import React, { Component } from "react"
import Slider from "react-slick"
import { StaticImage } from "gatsby-plugin-image"
import "../css/slick/slick.css"
import "../css/slick/slick-theme.css"

export default class Logos extends Component {
    render() {
      var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ]
      };
      return (
        <div className="logos-component">
          <Slider {...settings}>
            <div>
                <StaticImage src="../images/klanten/johanson-design.jpg" alt="Johanson Design" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/eelloo.jpg" alt="eelloo" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/bmw.jpg" alt="BMW" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/dsh.jpg" alt="Deutsche Schadenshilfe" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/universiteit-utrecht.jpg" alt="Universiteit Utrecht" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/mojogear.jpg" alt="MOJOGEAR" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/grazi.jpg" alt="GRAZI Overheidsstrategie" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/bb-kwadraat.jpg" alt="BBKwadraat" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/jsb.jpg" alt="JSB" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/pharmaccess.jpg" alt="PharmAccess" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/stichting-rijk.jpg" alt="RIJK" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/home67.jpg" alt="Home67" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/synergie-ingenieurs.jpg" alt="Synergie projectmanagement" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/careca-verhuur.jpg" alt="Careca Verhuur" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/gemeente-bloemendaal.jpg" alt="Gemeente Bloemendaal" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/gemeente-heemstede.jpg" alt="Gemeente Heemstede" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
            <div>
                <StaticImage src="../images/klanten/humind.jpg" alt="Humind" width={346} height={115.32} placeholder="none" className='klantlogo' />
            </div>
          </Slider>
        </div>
      );
    }
  }