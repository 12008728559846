import React from 'react'
import Layout from "../components/layout";
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import { FaGlobe, FaShoppingCart, FaCogs, FaSearch, FaChalkboard, FaScrewdriver, FaDatabase, FaPalette } from 'react-icons/fa';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Logos from '../components/logos';
import { Helmet } from 'react-helmet';


function index() {

  const sitename = "Bart Sallé, freelance webdeveloper";
  const pagetitle = "Home - Bart Sallé, freelance webdeveloper";
  const description = "Freelance webdeveloper. Je kunt bij mij terecht voor websites, webwinkels, op maat geschreven applicaties, SEO en meer.";
  const url = "https://www.bartsalle.nl/";

  return (
    <Layout>
      <Helmet>
        <title>{pagetitle}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pagetitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={sitename} />
        <body className="home" />
      </Helmet>
      <div className='container'>
        <div className="home1 blockcontainer">
          <div className='left'>
            <h1>Op zoek naar een freelance webdeveloper?</h1>
            <p>Begin een nieuw avontuur in het digitale tijdperk en ik help je daar graag bij! Met ruim 14 jaar ervaring als ontwikkelaar en content creator (ik maak ook designs!) heb ik genoeg kennis en kunde in huis om jouw nieuwe avontuur te laten slagen. Of wellicht heb je hulp of advies nodig bij een bestaand project?</p>
            <button id="diensten" onClick={() => scrollTo('#diensten')} className="button">Lees meer</button>
          </div>
          <div className='center' data-sal="fade" data-sal-delay="600" data-sal-easing="ease">
            <StaticImage src="../images/ikke.jpg" alt="Bart Sallé, freelance webdeveloper" layout="fixed" width={150} height={150} placeholder="none" className='round' />
          </div>
        </div>
      </div>
      <div className="container diensten" data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
        <div className="blockcontainer">
          <h2>Ervaren webdeveloper met veel kennis van en ervaring met Open Source software.</h2>
          <p>Als freelance webdeveloper kan ik met trots zeggen dat ik van mijn grootste hobby mijn beroep heb kunnen maken.<br /><Link to="/over/">Meer over mij</Link>.</p>
        </div>
        <div className="blockcontainer">
          <h2>Van veel markten thuis.</h2>
          <p>Naast developer ben ik ook designer en houd ik mij bezig met SEO (zoekmachine optimalisatie), Social Media Marketing & webrichtlijnen. Hieronder vind je een overzicht van mijn diensten.</p>
        </div>
      </div>
      <div className="container home3">
        <div className="blockcontainer">
          <div className="service" data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease">
            <h3><FaGlobe /> Websites</h3>
            <p>Websites bouw ik doorgaans op basis van WordPress. Ik vertel je graag meer over de voordelen en mogelijkheden van WordPress.</p>
            <p>Werk je met een ander CMS of wil je liever geen WordPress?<br /><Link to="/diensten/webdesign/">Lees meer over de mogelijkheden!</Link></p>
          </div>
          <div className="service" data-sal="slide-left" data-sal-delay="300" data-sal-easing="ease">
            <h3><FaShoppingCart /> Webwinkels</h3>
            <p>WooCommerce en Prestashop. Beiden enorm geschikt en ik help je graag bij het maken van de juiste keuze.<br /><Link to="/diensten/webdesign/">Lees meer...</Link></p>
          </div>
          <div className="service" data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease">
            <h3><FaCogs />Maatwerk</h3>
            <p>Van WordPress plugins tot Laravel applicaties en het schrijven van koppelingen.</p><p>Daarnaast ben ik zeer gecharmeerd van React en het bouwen van supersnelle websites op basis van React. Gevoed door een zogeheten headless CMS zoals Wordpress!<br /><Link to="/diensten/webdevelopment/">Lees meer...</Link></p>
          </div>
          <div className="service" data-sal="slide-left" data-sal-delay="300" data-sal-easing="ease">
            <h3><FaSearch /> SEO (zoekmachine optimalisatie)</h3>
            <p>Wordt je website niet goed (genoeg) gevonden? Ik maak SEO analyses waardoor precies naar boven komt waar de verbeterpunten liggen. In die analyse neem ik ook de concurrentie en linkbuilding mee en geef ik diverse tips.<br /><Link to="/diensten/hoger-in-google/">Lees meer...</Link></p>
          </div>
          <div className="service" data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease">
            <h3><FaPalette />Styling</h3>
            <p>Als frontend developer is styling toch één mijn favoriete bezigheden.</p><p>Wil je je website of webapplicatie ge(re)styled hebben? Ik lees het graag!<br /><Link to="/diensten/webdevelopment/">Lees meer...</Link></p>
          </div>
          <div className="service" data-sal="slide-left" data-sal-delay="300" data-sal-easing="ease">
            <h3><FaChalkboard /> Design & Content creation</h3>
            <p>Logo’s, flyers, folders of een compleet nieuwe huisstijl.<br /><Link to="/diensten/design-en-content-creation/">Lees meer...</Link></p>
          </div>
          <div className="service" data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease">
            <h3><FaScrewdriver /> Onderhoud</h3>
            <p>Een nieuwe website is leuk maar deze dient net, zoals bijvoorbeeld een auto, onderhouden te worden. Zo blijft de kans om gehackt te worden minimaal.<br /><Link to="/diensten/hosting-en-onderhoud/">Lees meer...</Link></p>
          </div>
          <div className="service" data-sal="slide-left" data-sal-delay="300" data-sal-easing="ease">
            <h3><FaDatabase /> Hosting</h3>
            <p>Goede hosting is heel belangrijk, zelfs vanuit SEO perspectief. Ook dit kan ik voor je uit handen nemen. Snelle SSD schijven en meerdere backups per dag.<br /><Link to="/diensten/hosting-en-onderhoud/">Lees meer...</Link></p>
          </div>
        </div>
      </div>
      <div className="container klanten">
        <div className="blockcontainer">
          <h2>Klanten</h2>
          <h3>Voor wie ik o.a. gewerkt heb of nog steeds werk.</h3>
          <Logos />
        </div>
      </div>
    </Layout>
  )
}

export default index